import React, { useEffect, useState } from "react";
import "./styles.css";
import { imgRocket } from "@imgs";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { getUrlImagesFB } from "@services/firebase";
import useDelayState from "../../hooks/useDelayState";
import companyAction from "@store/Company/CompanyAction";
import ChooseBizGroupHeader from "./ChooseBizGroupModalHeader";
import ChooseBizGroupModalList from "./ChooseBizGroupModalList";
import ChooseBizGroupModalFooter from "./ChooseBizGroupModalFooter";
import { CURRENT_USER_AND_BUSINESS_GROUP_QUERY } from "@query/gql/user";
import { deleteCompany, getUserFirstName, isAdmin, isComercial, setCompany, setGroup } from "@services/auth";
import { BUSINESS_GROUPS_SHARED_ENABLED_SIMPLE_QUERY, BUSINESS_GROUPS_SHARING_ENABLED_QUERY } from "@query/gql/groups";

interface IChooseBusinessGroupModalContentProps {
  onClose?: () => void;
  callBack?: () => void;
  setGroupsQuantity?: React.Dispatch<React.SetStateAction<number>>;
  isNewBusiness?: boolean;
}

export default function ChooseBusinessGroupModalContent({
  onClose = () => "",
  callBack = () => "",
  setGroupsQuantity,
  isNewBusiness = false,
}: IChooseBusinessGroupModalContentProps) {
  const dispatch = useDispatch();
  const userName = getUserFirstName();

  const [logos, setLogos] = useState({});
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useDelayState("");
  const [isLogoPopulated, setIsLogoPopulated] = useState(false);
  const [userBusinessGroups, setUserBusinessGroups] = useState([]);
  const [getGroupsSharedEnabledDetailed] = useLazyQuery(BUSINESS_GROUPS_SHARING_ENABLED_QUERY);
  const [getGroupsSharedEnabledSimple] = useLazyQuery(BUSINESS_GROUPS_SHARED_ENABLED_SIMPLE_QUERY);
  const [getCurrentUserAndBusinessGroup] = useLazyQuery(CURRENT_USER_AND_BUSINESS_GROUP_QUERY);

  const SPECIAL_BUSINESS_GROUPS: { [key: number]: string } = {
    1463: "Hamburgueria BIUD",
    2188: "Drogaria BIUD",
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        if (isAdmin()) {
          const groupsEnabledPromise = getGroupsSharedEnabledSimple({ fetchPolicy: "no-cache" });
          const [groupsEnabledRes] = await Promise.all([groupsEnabledPromise]);

          const bizGroups = groupsEnabledRes.data?.BusinessGroups?.data;
          handleUserBusinessGroups(bizGroups);

          return;
        }
        const resReq = await getCurrentUserAndBusinessGroup({ fetchPolicy: "no-cache" });
        const myUser = resReq.data;
        if (myUser.CurrentUser.email === "bbagro@biud.com.br" && myUser.CurrentUser.userBusinessGroups.length === 1) {
          return await handleSelectBusinessGroup(myUser.CurrentUser.userBusinessGroups[0]);
        }
        handleUserBusinessGroups(myUser?.CurrentUser.userBusinessGroups ?? []);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (isLogoPopulated) return;
    if (userBusinessGroups?.length === 0) return;

    setIsLogoPopulated(true);
    populateGroups();
  }, [userBusinessGroups, isLogoPopulated]);

  const handleUserBusinessGroups = (groups: any) => {
    if (setGroupsQuantity) {
      setGroupsQuantity(groups.length);
    }

    setUserBusinessGroups(groups);
  };

  const isProd = () => {
    return import.meta.env.VITE_ENV === "prod";
  };

  const searchLogoInEachBiz = (bizGroup: any) => {
    bizGroup.businesses?.forEach((biz: any, index: any) => {
      if (isComercial() && biz.name?.includes("Drogafuji")) biz.logo = null;
      if (isComercial() && biz.name?.includes("Ricco")) biz.logo = null;

      if (biz.logo && isProd()) {
        getUrlImagesFB(bizGroup.businesses[index].logo)
          .then((url) => {
            const newLogo = { [bizGroup.id]: url };
            setLogos((oldLogos) => ({ ...oldLogos, ...newLogo }));
          })
          .catch((err) => console.log(`Image not found in firebase. Update needed! error: ${err}`));
      } else {
        const newLogo = { [bizGroup.id]: imgRocket };
        setLogos((oldLogos) => ({ ...oldLogos, ...newLogo }));
      }
    });
  };

  const populateGroups = () => {
    userBusinessGroups?.forEach((bizGroup: any) => {
      if (isComercial() && bizGroup.id in SPECIAL_BUSINESS_GROUPS) bizGroup.logo = null;

      if (bizGroup.logo && isProd()) {
        getUrlImagesFB(bizGroup.logo)
          .then((url) => {
            const newLogo = { [bizGroup.id]: url };
            setLogos((oldLogos) => ({ ...oldLogos, ...newLogo }));
          })
          .catch((err) => console.log(`Image not found in firebase. Update needed! error: ${err}`));
      } else {
        searchLogoInEachBiz(bizGroup);
      }
    });
  };

  const handleSelectBusinessGroup = async (businessGroup: any) => {
    try {
      setLoading(true);
      let bg = businessGroup;

      if (isAdmin()) {
        const resReq = await getGroupsSharedEnabledDetailed({
          variables: { groupId: businessGroup.id },
          fetchPolicy: "no-cache",
        });
        bg = resReq.data?.BusinessGroups?.data;
        bg = bg?.[0];
      }

      if (isComercial() && bg.id in SPECIAL_BUSINESS_GROUPS) {
        bg.name = SPECIAL_BUSINESS_GROUPS[bg.id];
        bg.logo = null;
      }

      setGroup({ ...bg });
      setCompany(null);
      deleteCompany();
      onClose();
      callBack();
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="choose-business-group-content">
      <ChooseBizGroupHeader
        onClose={() => {
          onClose();
          if (isNewBusiness) dispatch(companyAction.setIsChooseGroupBusinessRequired(true));
        }}
        userName={userName}
        setSearch={setSearch}
      />
      <ChooseBizGroupModalList
        logos={logos}
        search={search}
        loading={loading}
        userBusinessGroups={userBusinessGroups}
        handleSelectBusinessGroup={handleSelectBusinessGroup}
      />
      <hr />
      <ChooseBizGroupModalFooter />
    </div>
  );
}
