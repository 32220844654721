import React from "react";
import { BsPhone } from "react-icons/bs";
import { AiOutlineTool } from "react-icons/ai";
import { RiPriceTag3Line } from "react-icons/ri";
import { MdAttachMoney, MdOutlineCampaign } from "react-icons/md";
import { BiAward, BiBrain, BiFileBlank, BiHome, BiShoppingBag } from "react-icons/bi";
import { canRequestHotleadsActivation, getUserEsg, isAdmin, isFinancialUser } from "@services/auth";
import {
  Cash,
  FileDownload,
  Globe,
  Graphics,
  IconSetting,
  MobileAndroid,
  User,
  Users,
  VolumeDown,
  Whatsapp,
} from "@newcomponentes/CustomIcons";
import { isGsGroupId } from "@/services/utils";

export interface IMenuOption {
  id: string;
  name: string;
  title: string;
  optionName: string;
  isEnabled: boolean;
  options?: IMenuOption[];
  url?: string;
  icon?: JSX.Element;
  routeName?: string;
  isAdminOnly?: boolean;
  tooltip?: string;
}

export const unitMenuOptions = [
  {
    id: "btn-menu-customers",
    name: "Hot Leads",
    url: "/internal/hotleads",
    title: "Hot Leads",
    icon: <Users style={{ fontSize: "14px" }} />,
    routeName: "hotleads",
    optionName: "hotleads",
    isEnabled: false,
  },
  {
    id: "btn-menu-campaigns",
    name: "Campanhas",
    url: "/internal/campaigns",
    title: "Campanhas",
    icon: <MdOutlineCampaign />,
    routeName: "campaigns",
    optionName: "campaigns",
    isEnabled: import.meta.env.VITE_ENV !== "prod",
  },
];

export const storeMenuOptions: IMenuOption[] = [
  {
    id: "btn-menu-start",
    name: "Início",
    url: "/internal/home",
    title: "Início",
    icon: <BiHome />,
    routeName: "home",
    optionName: "start",
    isEnabled: true,
  },
  {
    id: "btn-menu-dashboard",
    name: "Dashboard",
    url: "/internal/dashboard",
    title: "Dashboard",
    icon: <Graphics style={{ fontSize: "12px" }} />,
    routeName: "dashboard",
    optionName: "start",
    isEnabled: true,
  },
  {
    id: "btn-menu-socialmedia",
    name: "Redes Sociais",
    url: "/internal/socialmedia",
    title: "Redes Sociais",
    icon: <BsPhone />,
    routeName: "socialmedia",
    optionName: "socialmedia",
    isEnabled: isAdmin(),
    isAdminOnly: true,
    tooltip: "Seção visível apenas para administradores devido a problema de permissões no facebook.",
  },
  {
    id: "btn-menu-customers",
    name: "Hot Leads",
    url: "/internal/hotleads",
    title: "Hot Leads",
    icon: <Users style={{ fontSize: "14px" }} />,
    routeName: "hotleads",
    optionName: "hotleads",
    isEnabled: isGsGroupId() || canRequestHotleadsActivation(),
  },
  {
    id: "btn-menu-intelligence",
    name: "Intelligence",
    title: "Intelligence",
    optionName: "intelligence",
    isEnabled: true,
    options: [
      {
        id: "btn-navmenu-customers",
        name: "Clientes",
        url: "/internal/customers",
        title: "Clientes",
        icon: <User style={{ fontSize: "12px" }} />,
        routeName: "customers",
        optionName: "customers",
        isEnabled: true,
      },
      {
        id: "btn-navmenu-sales",
        name: "Vendas",
        url: "/internal/sales",
        title: "Vendas",
        icon: <MdAttachMoney />,
        routeName: "sales",
        optionName: "sales",
        isEnabled: true,
      },
      {
        id: "btn-navmenu-products",
        name: "Produtos",
        url: "/internal/products?tab=1",
        title: "Produtos",
        icon: <BiShoppingBag />,
        routeName: "products",
        optionName: "products",
        isEnabled: true,
      },
    ],
  },
  {
    id: "btn-menu-automations",
    name: "Automações",
    title: "Automações",
    optionName: "automations",
    isEnabled: true,
    options: [
      {
        id: "btn-navmenu-activations",
        name: "Ativações",
        url: "/internal/activations",
        title: "Ativações",
        icon: <BiAward />,
        routeName: "activations",
        optionName: "activations",
        isEnabled: true,
      },
      {
        id: "btn-navmenu-insights",
        name: "Listas Inteligentes",
        url: "/internal/insights",
        title: "Listas Inteligentes",
        icon: <BiBrain />,
        routeName: "insights",
        optionName: "insights",
        isEnabled: true,
      },
      {
        id: "btn-navmenu-integrations",
        name: "Integrações",
        url: "/internal/integrations",
        title: "Configurações de mensageria",
        icon: <Whatsapp style={{ fontSize: 14 }} />,
        routeName: "integrations",
        optionName: "integrations",
        isEnabled: true,
      },
      {
        id: "btn-navmenu-noise-control",
        name: "Controle de Ruído",
        url: "/internal/noise-control",
        title: "Ajustes do controle de ruído",
        icon: <VolumeDown style={{ fontSize: 16 }} />,
        routeName: "noise-control",
        optionName: "noise-control",
        isEnabled: true,
      },
    ],
  },
  {
    id: "btn-menu-registrations",
    name: "Cadastros",
    title: "Cadastros",
    optionName: "registrations",
    isEnabled: true,
    options: [
      {
        id: "btn-navmenu-landing-pages",
        name: "Landing Pages",
        url: "/internal/landing-pages",
        title: "Configure suas Landing Pages",
        icon: <MobileAndroid style={{ fontSize: 16 }} />,
        routeName: "landing-pages",
        optionName: "landing-pages",
        isEnabled: true,
      },
      {
        id: "btn-navmenu-offers",
        name: "Ofertas",
        url: "/internal/offers",
        title: "Ofertas",
        icon: <RiPriceTag3Line />,
        routeName: "offers",
        optionName: "offers",
        isEnabled: true,
      },
    ],
  },
  {
    id: "btn-menu-data",
    name: "Dados",
    title: "Dados",
    optionName: "data",
    isEnabled: true,
    options: [
      {
        id: "btn-navmenu-data",
        name: "Coletores",
        url: "/internal/data",
        title: "Coletores",
        icon: <BiFileBlank />,
        routeName: "data",
        optionName: "data",
        isEnabled: true,
      },
      {
        id: "btn-navmenu-reports",
        name: "Extrações",
        url: "/internal/reports",
        title: "Extrações",
        icon: <FileDownload style={{ fontSize: "14px" }} />,
        routeName: "reports",
        optionName: "reports",
        isEnabled: true,
      },
      {
        id: "btn-navmenu-enrichment",
        name: "Enriquecimentos",
        url: "/internal/enrichment",
        title: "Enriquecimentos",
        icon: <Users style={{ fontSize: "14px" }} />,
        routeName: "enrichment",
        optionName: "enrichment",
        isEnabled: true,
      },
    ],
  },
  {
    id: "btn-menu-esg",
    name: "ESG",
    url: "/internal/esg",
    title: "ESG",
    icon: <Globe style={{ fontSize: 14, marginTop: -1 }} />,
    routeName: "esg",
    optionName: "esg",
    isEnabled: getUserEsg(),
  },
  {
    id: "btn-menu-billing",
    name: "Financeiro",
    url: "/internal/billing",
    title: "Financeiro",
    icon: <Cash style={{ fontSize: "14px" }} />,
    routeName: "finance",
    optionName: "finance",
    isEnabled: true,
  },
  {
    id: "btn-menu-configurations",
    name: "Configurações",
    url: "/internal/configurations?tab=1",
    title: "Configurações",
    icon: <AiOutlineTool />,
    routeName: "configurations",
    optionName: "configurations",
    isEnabled: true,
  },
  {
    id: "btn-menu-admin",
    name: "Administração",
    url: "/internal/admin",
    title: "Administração",
    icon: <IconSetting style={{ color: "#0095FF", fontSize: "16px" }} />,
    routeName: "admin",
    optionName: "admin",
    isEnabled: isFinancialUser(),
  },
];
