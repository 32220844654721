import { gql } from "@/graphql/config";

export const BUSINESS_GROUPS_SHARING_ENABLED_QUERY = gql`
  query BusinessGroupsSharingEnabled($groupId: Int) {
    BusinessGroups(businessActive: true, businessDataSharingEnabled: true, id: $groupId) {
      data {
        id
        name
        logo
        readonly
        plan {
          name
          leadsDataFranchise
        }
        businesses {
          id
          name
          cnpj
          logo
          instagram
          facebook
          siteUrl
          isDataSharingEnabled
          operatingAreas {
            id
            name
          }
          activationRequestParent {
            externalId
          }
        }
      }
    }
  }
`;

export const BUSINESS_GROUPS_SHARED_ENABLED_SIMPLE_QUERY = gql`
  query BusinessGroupsEnabledSimple {
    BusinessGroups(businessDataSharingEnabled: true, businessActive: true) {
      data {
        id
        name
        readonly
        logo
        businesses {
          id
        }
      }
    }
  }
`;
