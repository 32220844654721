import React from "react";
import { IMenuOption } from "./unit";
import { CgPlug } from "react-icons/cg";
import { BsPerson } from "react-icons/bs";
import { getGroup } from "@/services/auth";
import { MdAttachMoney } from "react-icons/md";
import { isGsGroupId } from "@/services/utils";
import { BiHome, BiShoppingBag } from "react-icons/bi";
import { AiOutlineRise, AiOutlineTool } from "react-icons/ai";
import ChartPieAlt from "@newcomponentes/CustomIcons/Generics/ChartPieAlt";
import SocialDistancing from "@newcomponentes/CustomIcons/Generics/SocialDistancing";
import {
  Cash,
  CommentHeart,
  FileDownload,
  Graphics,
  Rocket2,
  Star,
  Thunder,
  Users,
  Whatsapp,
} from "@newcomponentes/CustomIcons";

export const disabledNetworkMenuOptions = [
  {
    id: "btn-menu-ntperception",
    name: "Percepção",
    url: "/internal/network/perception",
    title: "Percepção",
    icon: <CommentHeart style={{ fontSize: "14px" }} />,
    routeName: "network/perception",
    optionName: "ntPerception",
    isEnabled: false,
  },
  {
    id: "btn-menu-ntinsight",
    name: "Insights",
    url: "/internal/network/insights",
    title: "Insights da rede",
    icon: <AiOutlineRise />,
    routeName: "network/insights",
    optionName: "ntInsights",
    isEnabled: false,
  },
];

export const networkMenuOptions: IMenuOption[] = [
  {
    id: "btn-menu-ntstart",
    name: "Início",
    url: "/internal/network/home",
    title: "Início",
    icon: <BiHome />,
    routeName: "network/home",
    optionName: "ntHome",
    isEnabled: true,
  },
  {
    id: "btn-menu-ntdashboard",
    name: "Dashboard",
    url: "/internal/network/dashboard",
    title: "Home da rede",
    icon: <Graphics style={{ fontSize: "12px" }} />,
    routeName: "network/dashboard",
    optionName: "ntDashboard",
    isEnabled: true,
  },
  {
    id: "btn-menu-ntGeneral",
    name: "General",
    title: "General",
    optionName: "ntGeneral",
    isEnabled: isGsGroupId(),
    options: [
      {
        id: "btn-menu-general-dashboard",
        name: "Dashboard",
        url: "/internal/network/general-dashboard",
        title: "General Shopping Home",
        icon: <Star style={{ fontSize: "12px" }} />,
        routeName: "network/general-dashboard",
        optionName: "ntGeneralDashboard",
        isEnabled: true,
      },
      {
        id: "btn-menu-general-light",
        name: "Home lite",
        url: "/internal/network/general-lite",
        title: "General Shopping Home Lite",
        icon: <Star style={{ fontSize: "12px" }} />,
        routeName: "network/general-lite",
        optionName: "ntGeneralLightDashboard",
        isEnabled: true,
      },
      {
        id: "btn-menu-general-wifi",
        name: "Wi-Fi",
        url: "/internal/network/general-wifi",
        title: "General Shopping Home Wi-Fi",
        icon: <Star style={{ fontSize: "12px" }} />,
        routeName: "network/general-wifi",
        optionName: "ntGeneralWifiDashboard",
        isEnabled: true,
      },
      {
        id: "btn-menu-general-hotlead",
        name: "Hot Leads",
        url: "/internal/network/hotleads",
        title: "GS Hot Leads",
        icon: <Star style={{ fontSize: "12px" }} />,
        routeName: "network/hotleads",
        optionName: "ntGeneralHotleads",
        isEnabled: true,
      },
    ],
  },
  {
    id: "btn-menu-ntIntelligence",
    name: "Intelligence",
    title: "Intelligence",
    optionName: "ntIntelligence",
    isEnabled: true,
    options: [
      {
        id: "btn-menu-ntfindout",
        name: "Descobrir",
        url: "/internal/network/findout",
        title: "Descobrir",
        icon: <Thunder style={{ fontSize: "14px" }} />,
        routeName: "network/findout",
        optionName: "ntFindout",
        isEnabled: true,
      },
      {
        id: "btn-menu-ntdashcustomer",
        name: "Clientes",
        url: "/internal/network/customers-dashboard",
        title: "Clientes da rede",
        icon: <BsPerson />,
        routeName: "network/customers",
        optionName: "ntCustomers",
        isEnabled: true,
      },
      {
        id: "btn-menu-ntdashsales",
        name: "Vendas",
        url: "/internal/network/sales-dashboard",
        title: "Vendas da rede",
        icon: <MdAttachMoney />,
        routeName: "network/sales",
        optionName: "ntSales",
        isEnabled: true,
      },
      {
        id: "btn-menu-ntproducts",
        name: "Produtos",
        url: "/internal/network/products",
        title: "Produtos da rede",
        icon: <BiShoppingBag />,
        routeName: "network/products",
        optionName: "ntProducts",
        isEnabled: true,
      },
      {
        id: "btn-menu-ntleads",
        name: "Leads",
        url: "/internal/network/leads",
        title: "Leads",
        icon: <BiShoppingBag />,
        routeName: "network/leads",
        optionName: "ntLeads",
        isEnabled: getGroup()?.id === 3189,
      },
    ],
  },
  {
    id: "btn-menu-ntautomations",
    name: "Automações",
    title: "Automações da rede",
    optionName: "ntAutomations",
    isEnabled: true,
    options: [
      {
        id: "btn-menu-ntactivations",
        name: "Ativações",
        url: "/internal/network/activations",
        title: "Ativações da rede",
        icon: <Rocket2 style={{ fontSize: "14px" }} />,
        routeName: "network/activations",
        optionName: "ntActivations",
        isEnabled: true,
      },
      {
        id: "btn-navmenu-ntintegrations",
        name: "Integrações",
        url: "/internal/network/integrations",
        title: "Configurações de mensageria",
        icon: <Whatsapp style={{ fontSize: 14 }} />,
        routeName: "network/integrations",
        optionName: "ntIntegrations",
        isEnabled: true,
      },
      {
        id: "btn-menu-ntpublics",
        name: "Públicos",
        url: "/internal/network/publics",
        title: "Públicos da rede",
        icon: <SocialDistancing style={{ width: 16, height: 16, marginTop: 0 }} viewBox="0 0 16 16" />,
        routeName: "network/publics",
        optionName: "ntPublics",
        isEnabled: false,
      },
      {
        id: "btn-menu-ntplugins",
        name: "Plugins",
        url: "/internal/network/plugins",
        title: "Plugins da rede",
        icon: <CgPlug />,
        routeName: "network/plugins",
        optionName: "ntPlugins",
        isEnabled: false,
      },
    ],
  },
  {
    id: "btn-menu-ntanalytics",
    name: "Analytics",
    url: "/internal/network/analytics",
    title: "Analytics",
    icon: <ChartPieAlt style={{ fontSize: "14px" }} />,
    routeName: "network/analytics",
    optionName: "ntAnalytics",
    isEnabled: true,
  },
  {
    id: "btn-menu-ntdata",
    name: "Dados",
    title: "Dados",
    optionName: "ntData",
    isEnabled: true,
    options: [
      {
        id: "btn-menu-ntreports",
        name: "Extrações",
        url: "/internal/network/reports",
        title: "Relatórios da rede",
        icon: <FileDownload style={{ fontSize: "14px" }} />,
        routeName: "network/reports",
        optionName: "ntReports",
        isEnabled: true,
      },
      {
        id: "btn-navmenu-ntenrichment",
        name: "Enriquecimentos",
        url: "/internal/network/enrichment",
        title: "Enriquecimentos",
        icon: <Users style={{ fontSize: "14px" }} />,
        routeName: "network/enrichment",
        optionName: "ntEnrichment",
        isEnabled: true,
      },
    ],
  },
  {
    id: "btn-menu-ntbilling",
    name: "Financeiro",
    url: "/internal/network/billing",
    title: "Financeiro",
    icon: <Cash style={{ fontSize: "14px" }} />,
    routeName: "network/billing",
    optionName: "ntBilling",
    isEnabled: true,
  },
  {
    id: "btn-menu-ntconfig",
    name: "Configurações",
    url: "/internal/network/configurations",
    title: "Configurações da rede",
    icon: <AiOutlineTool />,
    routeName: "network/configurations",
    optionName: "ntConfigurations",
    isEnabled: true,
  },
];
