import { lazy } from "react";
import Updates from "@pages/Updates";

export { default as LoadingPage } from "./LoadingPage/LoadingPage";
export { default as Welcome } from "./Welcome";
//Unit
export const CustomersLazy = lazy(() => import("./Customers"));
export const CustomersB2BLazy = lazy(() => import("./CustomersB2B"));
export const SalesLazy = lazy(() => import("./Sales"));
export const CampaignsLazy = lazy(() => import("./Campaigns"));
export const ProductsLazy = lazy(() => import("./Products"));
export const SocialMediaLazy = lazy(() => import("./SocialMedia"));
export const ConfigurationsLazy = lazy(() => import("./Configurations"));
export const OffersLazy = lazy(() => import("./Offers"));
export const NewOfferLazy = lazy(() => import("./Offers/NewOffer"));
export const BusinessRegisterLazy = lazy(() => import("./BusinessRegister"));
export const BusinessRegister1Lazy = lazy(() => import("./BusinessRegister1"));
export const InsightsLazy = lazy(() => import("./Insights"));
export const WhatsAppWebLazy = lazy(() => import("./WhatsAppWeb"));
export const ReportsLazy = lazy(() => import("./Reports"));
export const UpdatesLazy = lazy(() => import("./Updates"));
export const EsgPageLazy = lazy(() => import("./ESG"));
export const EnrichmentLazy = lazy(() => import("./Enrichment"));
export const IntegrationsLazy = lazy(() => import("./Integrations"));
export const NoiseControlLazy = lazy(() => import("./NoiseControl"));
export const LandingPagesLazy = lazy(() => import("./LandingPages"));
export const ActivationsLazy = lazy(() => import("./Activations"));
export const DataLazy = lazy(() => import("./Data"));
export const HotLeadsLazy = lazy(() => import("./HotLeads"));
export const esgBbAgroLazy = lazy(() => import("./EsgBbAgro"));
export const HomeLazy = lazy(() => import("./Home"));
export const HomeLightLazy = lazy(() => import("./HomeLight"));
export const BillingLazy = lazy(() => import("./Billing"));
//Network
export const NetworkDashboardLazy = lazy(() => import("./NetworkDashboard"));
export const NetworkPerceptionLazy = lazy(() => import("./NetworkPerception"));
export const NetworkIntegrationsLazy = lazy(() => import("./NetworkIntegrations"));
export const NetworkFindoutLazy = lazy(() => import("./NetworkFindout"));
export const NetworkPublicsLazy = lazy(() => import("./NetworkPublics"));
export const NetworkPluginsLazy = lazy(() => import("./NetworkPlugins"));
export const NetworkAnalyticsLazy = lazy(() => import("./NetworkAnalytics"));
export const NetworkHotLeadsLazy = lazy(() => import("./NetworkHotLeads"));
export const NetworkConfigurationLazy = lazy(() => import("./NetworkConfiguration/NetworkConfiguration"));
export const NetworkCustomersLazy = lazy(() => import("./NetworkCustomers"));
export const NetworkReportsLazy = lazy(() => import("./NetworkReports"));
export const NetworkProductsLazy = lazy(() => import("./NetworkProducts"));
export const NetworkSalesLazy = lazy(() => import("./NetworkSales"));
export const NetworkSalesListLazy = lazy(() => import("./NetworkSalesList"));
export const NetworkInsightsLazy = lazy(() => import("./NetworkInsights"));
export const NetworkDashClientLazy = lazy(() => import("./NetworkDashClient"));
export const LeadsAutoLazy = lazy(() => import("./LeadsAuto"));
export const GeneralShoppingLazy = lazy(() => import("./GeneralShopping"));
export const GeneralShoppingLiteLazy = lazy(() => import("./GeneralShopping/GSLite"));
export const GeneralShoppingWifiLazy = lazy(() => import("./GeneralShopping/GsWifi"));
//CS
export const CSDashLazy = lazy(() => import("@pages/CSDash"));
export const CSBusinessPanelLazy = lazy(() => import("@pages/CSBusinessPanel"));
//Public
export const SebraeCertificateReportLazy = lazy(() => import("./SebraeCertificateReport"));
export const BbAgroCertificateReportLazy = lazy(() => import("./BbAgroCertificateReport"));
export const LoginLazy = lazy(() => import("./Login"));
export const Login1Lazy = lazy(() => import("./Login1"));
export const LoginV1Lazy = lazy(() => import("./LoginV1"));
export const RegisterEvaluationUserLazy = lazy(() => import("./RegisterEvaluationUser"));
export const UserRegisterEmbedLazy = lazy(() => import("./UserRegisterEmbed/UserRegisterEmbed"));
export const RegisterLazy = lazy(() => import("./Register"));
export const RegisterV1Lazy = lazy(() => import("./RegisterV1"));
export const ForgotPasswordV1Lazy = lazy(() => import("./ForgotPasswordV1"));
export const NewPasswordV1Lazy = lazy(() => import("./NewPasswordV1"));
export const NewPasswordLazy = lazy(() => import("./NewPassword"));
//Admin
export const AdminLazy = lazy(() => import("./Admin"));
export const AdminActivationLazy = lazy(() => import("./AdminActivation"));
export const AdminPlansLazy = lazy(() => import("./AdminPlans"));
export const EnrichmentAdminLazy = lazy(() => import("./Enrichment"));
export const HotleadsAdminLazy = lazy(() => import("./AdminHotLeads"));
