import React, { useEffect } from "react";
import CSRoute from "./CSRoute";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { useMutation } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import { companySelected } from "@services/auth";
import PrivateAdminRoute from "@routes/PrivateAdminRoute";
import PrivateNetworkRoute from "@routes/PrivateNetworkRoute";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { LOG_BUSINESS_INTERACTIVITY_MUTATION } from "@mutation/gql/user";
import {
  BbAgroCertificateReportLazy,
  ForgotPasswordV1Lazy,
  Login1Lazy,
  LoginLazy,
  LoginV1Lazy,
  NewPasswordLazy,
  NewPasswordV1Lazy,
  RegisterEvaluationUserLazy,
  RegisterLazy,
  RegisterV1Lazy,
  SebraeCertificateReportLazy,
  UserRegisterEmbedLazy,
} from "@pages";

export default function Routes() {
  const [logBusinessInteractivity] = useMutation(LOG_BUSINESS_INTERACTIVITY_MUTATION);

  useEffect(() => {
    window.history.pushState = (function (oldPushFunc) {
      return async function (this: any) {
        // @ts-expect-error
        // eslint-disable-next-line prefer-rest-params
        const ret = oldPushFunc.apply(this, arguments);
        if (companySelected()) {
          try {
            const logData = {
              businessId: companySelected(),
              message: "Acessando o endereço: " + window.location.pathname,
              section: window.location.pathname.replace("/internal/", ""),
            };
            await logBusinessInteractivity({ variables: logData, fetchPolicy: "no-cache" });
          } catch (err: any) {
            console.log(err.message);
          }
        }
        return ret;
      };
    })(window.history.pushState);
  }, []);

  const loadingLinear = (
    <LinearProgress sx={{ backgroundColor: "white", "& .MuiLinearProgress-bar": { backgroundColor: "#C4C4C4" } }} />
  );

  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <PublicRoute path="/login" component={LoginLazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/login1" component={Login1Lazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/login-v1" component={LoginV1Lazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/userregister" component={RegisterLazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/register-v1" component={RegisterV1Lazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/forgot-password-v1" component={ForgotPasswordV1Lazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/new-password-v1/:token" component={NewPasswordV1Lazy} suspenseFallback={loadingLinear} />
        <PublicRoute
          path="/userregister1/:email?/:nome?/:telefone?"
          component={Login1Lazy}
          suspenseFallback={loadingLinear}
        />
        <PublicRoute
          path="/evaluationregister"
          component={RegisterEvaluationUserLazy}
          suspenseFallback={loadingLinear}
        />
        <PublicRoute path="/esqueci-a-senha/:token" component={NewPasswordLazy} suspenseFallback={loadingLinear} />
        <PublicRoute path="/user-register-embed" component={UserRegisterEmbedLazy} suspenseFallback={loadingLinear} />
        <PublicRoute
          path="/validate-sebrae-certificate/:businessId?/:certificateNumber?"
          component={SebraeCertificateReportLazy}
          isAuthenticatedRedirectEnabled={false}
          suspenseFallback={loadingLinear}
        />
        <PublicRoute
          path="/validate-bb-agro-certificate/:businessId?/:certificateNumber?"
          component={BbAgroCertificateReportLazy}
          isAuthenticatedRedirectEnabled={false}
          suspenseFallback={loadingLinear}
        />
        <PrivateNetworkRoute path="/internal/network" />
        <PrivateAdminRoute path="/internal/admin" />
        <PrivateRoute path="/internal" />
        <CSRoute path="/cs" />
        <Redirect from="*" to="/login" />
      </Switch>
    </BrowserRouter>
  );
}
